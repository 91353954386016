import { Space, ColorPicker, Typography } from 'antd';

const OtherSettings = () => {
    const setColorHex = (value) => {
        const getHeaderElems = document.getElementsByClassName(
            'lg-header-section-title'
        ) as HTMLCollectionOf<HTMLElement>;

        const getBodyElems = document.getElementsByClassName(
            'lg-page-two-top-left-title'
        ) as HTMLCollectionOf<HTMLElement>;

        const titles = document.querySelectorAll(
            '.lg-page-two-top-left-title h2'
        ) as NodeListOf<HTMLElement>;

        titles.forEach((title) => {
            title.style.color = '#000';
        });

        getHeaderElems[0].style.backgroundColor = value.toHexString();
        getBodyElems[0].style.borderLeftColor = value.toHexString();
    };

    return (
        <Space>
            <Space>
                <ColorPicker
                    onChange={setColorHex}
                    defaultValue="#1677ff"
                    placement="topLeft"
                />
                <Typography.Text>Цвет плашки</Typography.Text>
            </Space>
        </Space>
    );
};

export default OtherSettings;
